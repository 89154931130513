import React from "react";
import {
  HTMLNode,
  TextNode,
  ComponentNode,
  ContainerNode,
  DocumentNode,
  ManagedValue,
  NullishValue,
  PrimitiveValue,
  FunctionValue,
  ArrayValue,
  ObjectValue,
  DocumentNodeValue,
  parseValue,
} from "../ast";
import { createContextManager, defined } from "../shared";
import { Dependency, Import, Declaration, getTracker, isTrackedObject } from "../ast";
import { importRegistry } from "./import-registry";
import { ValueNode } from "../ast/document";

function* chain<T>(generators: Iterable<Generator<T>>): Generator<T> {
  for (const item of generators) {
    yield* item;
  }
}

function resolveReference(value: unknown): string {
  return withDependencyTracking((state) => {
    if (importRegistry.isRegistered(value)) {
      value = importRegistry.resolve(value);
    }
    if (isTrackedObject(value)) {
      const tracker = getTracker(value);
      let name: string;
      if (tracker.target instanceof Import || tracker.target instanceof Declaration) {
        defined(state?.dependencies, "rendered without tracking context").add(tracker.target);
        name = tracker.target.name;
      } else {
        name = resolveReference(tracker.target.value);
      }
      return `${name}${tracker.accesses
        .map((access) => {
          if (access.type === "apply") {
            return `(${access.args.map((value) => Array.from(renderValueToTokens(value)).join("")).join(", ")})`;
          }
          if (isNaN(Number(access.field)) || isNaN(parseFloat(access.field))) {
            return `.${access.field}`;
          } else {
            // means access.field is numerical
            return `[${access.field}]`;
          }
        })
        .join("")}`;
    } else {
      console.error(
        "Unable to resolve reference, ensure the value is tracked and registered.",
        value,
      );
      throw new Error(
        "Unable to resolve reference, check the console message for more information",
      );
    }
  });
}

function* renderValueToTokens(managed: ManagedValue): Generator<string> {
  if (managed instanceof FunctionValue || isTrackedObject(managed.value)) {
    yield resolveReference(managed.value);
  } else if (managed instanceof PrimitiveValue) {
    if (typeof managed.value === "string") {
      yield `"${managed.value}"`;
    } else {
      yield `${managed.value}`;
    }
  } else if (managed instanceof ArrayValue) {
    yield `[`;
    for (const item of managed.items) {
      yield* renderValueToTokens(item);
      yield `,`;
    }
    yield `]`;
  } else if (managed instanceof ObjectValue) {
    yield `{`;
    for (const [k, v] of managed.entries) {
      yield `${k}:`;
      yield* renderValueToTokens(v);
      yield `,`;
    }
    yield `}`;
  } else if (managed instanceof DocumentNodeValue) {
    yield* renderToTokens(managed.node);
  } else if (managed instanceof NullishValue) {
    yield `undefined`;
  }
}

function* renderPropsToTokens(props: ObjectValue): Generator<string> {
  for (const [key, value] of props.entries) {
    if (value instanceof PrimitiveValue && typeof value.value === "string") {
      yield ` ${key}=`;
      if (key === "className") {
        yield `"${value.value.replace(/\s\s+/g, " ").trim()}"`;
      } else {
        yield* renderValueToTokens(value);
      }
    } else {
      yield ` ${key}={`;
      yield* renderValueToTokens(value);
      yield `}`;
    }
  }
}

function* renderToTokens(node: DocumentNode): Generator<string> {
  if (node instanceof ValueNode) {
    yield "{";
    yield resolveReference(node.value);
    yield "}";
    return;
  }
  if (node instanceof TextNode) {
    yield node.text;
    return;
  }
  if (node instanceof HTMLNode || node instanceof ComponentNode) {
    const tagName = node instanceof HTMLNode ? node.tagName : resolveReference(node.Component);
    if (node.children.length === 0) {
      yield `<${tagName}`;
      yield* renderPropsToTokens(node.props);
      yield `/>`;
    } else {
      yield `<${tagName}`;
      yield* renderPropsToTokens(node.props);
      yield `>`;
      yield* chain(node.children.map(renderToTokens));
      yield `</${tagName}>`;
    }
    return;
  }
  if (node instanceof ContainerNode) {
    yield* chain(node.children.map(renderToTokens));
    return;
  }
}

function renderNodeToString(node: DocumentNode): string {
  return Array.from(renderToTokens(node)).join("");
}

const withDependencyTracking = createContextManager<
  | {
      dependencies: Set<Dependency>;
    }
  | undefined
>(undefined);

function* renderImportsToTokens(imports: Import[]) {
  const nameImportMap: Map<string, Set<Import>> = new Map();
  const defaultImportMap: Map<string, Import> = new Map();
  const namespaceImportMap: Map<string, Import> = new Map();
  const allPaths = new Set<string>();
  imports.forEach((dependency) => {
    allPaths.add(dependency.path);
    if (dependency.form === "named") {
      if (!nameImportMap.has(dependency.path)) {
        nameImportMap.set(dependency.path, new Set());
      }
      nameImportMap.get(dependency.path)!.add(dependency);
    } else if (dependency.form === "default") {
      defaultImportMap.set(dependency.path, dependency);
    } else if (dependency.form === "namespace") {
      namespaceImportMap.set(dependency.path, dependency);
    }
  });
  for (const path of Array.from(allPaths).sort((a, b) => a.localeCompare(b))) {
    if (namespaceImportMap.has(path)) {
      yield `import * as ${namespaceImportMap.get(path)!.name} from "${path}";`;
    }

    const sections: string[] = [];
    if (defaultImportMap.has(path)) {
      sections.push(`${defaultImportMap.get(path)!.name}`);
    }
    if (nameImportMap.has(path)) {
      sections.push(
        `{ ${Array.from(nameImportMap.get(path)!)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((imported) => imported.name)
          .join(", ")} }`,
      );
    }
    if (sections.length > 0) {
      yield `import ${sections.join(", ")} from "${path}";`;
    }
  }
}

export function renderToSource(node: DocumentNode, name = "Section"): string {
  return withDependencyTracking((_, setState) => {
    const dependencies = new Set<Dependency>([new Import("React", React, "default", "react")]);
    setState({ dependencies });
    const jsx = renderNodeToString(node);
    const imports: Import[] = [];
    const statics: string[] = [];
    const dynamics: string[] = [];
    while (dependencies.size > 0) {
      const dependency = defined(dependencies.values().next().value);
      dependencies.delete(dependency);
      if (dependency.type === "declaration") {
        dependency.dependencies.forEach(resolveReference);
        if (dependency.scope === "static") {
          if (dependency.src?.js != null) {
            statics.push(`const ${dependency.name} =` + dependency.src.js) + ";";
          } else {
            statics.push(
              `const ${dependency.name} =` +
                Array.from(renderValueToTokens(parseValue(dependency.value))).join("") +
                ";",
            );
          }
        } else {
          if (dependency.src?.js != null) {
            dynamics.push(`const ${dependency.name} = ` + dependency.src.js) + ";";
          } else {
            dynamics.push(
              `const ${dependency.name} = ` +
                Array.from(renderValueToTokens(parseValue(dependency.value))).join("") +
                ";",
            );
          }
        }
      } else {
        imports.push(dependency);
      }
    }
    const importSection = Array.from(renderImportsToTokens(imports)).join("\n");
    return `"use client"\n\n${importSection}\n\n${statics.join("\n\n")}\n\nexport function ${name}() {\n${dynamics.reverse().join("\n")}return (${jsx});\n}`;
  });
}
