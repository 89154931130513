import { z } from "zod";
import {
  ComponentNode,
  ContainerNode,
  ElementNode,
  HTMLNode,
  SlotNode,
  TextNode,
  DocumentNode,
  ArrayValue,
  ManagedValue,
  ObjectValue,
  PrimitiveValue,
  DocumentNodeValue,
  NullishValue,
  FunctionValue,
} from "./ast";
import { defined } from "./shared";
import { ValueNode } from "./ast/document";

export type ElementState = {
  name: string;
  texts?: readonly (readonly [string, string])[];
  slots?: Record<string, ElementState[]>;
  lists?: Record<string, ElementState[]>;
};

export const elementStateSchema: z.ZodType<ElementState> = z.object({
  name: z.string(),
  texts: z.optional(z.array(z.tuple([z.string(), z.string()]))),
  slots: z.optional(z.record(z.array(z.lazy(() => elementStateSchema)))),
  lists: z.optional(z.record(z.array(z.lazy(() => elementStateSchema)))),
});

function match(
  object: Record<string, ElementState[]> | undefined,
  node: { match: (name: string) => boolean },
) {
  if (object == null) {
    return;
  }
  const entry = Object.entries(object).find(([key]) => node.match(key));
  if (entry != null) {
    return entry;
  }
}

type EditingOptions = {
  safe: boolean;
};

function applyPropEdits<T extends ManagedValue>(
  value: T,
  element: ElementState,
  options: EditingOptions = { safe: false },
): T {
  if (value instanceof NullishValue) {
    return value;
  }
  if (value instanceof PrimitiveValue) {
    const override =
      typeof value.value === "string" ? new Map(element.texts ?? []).get(value.value) : undefined;
    return <T extends PrimitiveValue ? T : never>new PrimitiveValue(override ?? value.value);
  }
  if (value instanceof ArrayValue) {
    return new ArrayValue(
      value,
      value.items.map((item) => applyPropEdits(item, element, options)),
    ) as T extends ArrayValue ? T : never;
  }
  if (value instanceof ObjectValue) {
    return new ObjectValue(
      value,
      value.entries.map(([k, v]) => [k, applyPropEdits(v, element, options)]),
    ) as T extends ObjectValue ? T : never;
  }
  if (value instanceof DocumentNodeValue) {
    return new DocumentNodeValue(
      value.value,
      applyEdits(value.node, element, options),
    ) as T extends DocumentNodeValue ? T : never;
  }
  if (value instanceof FunctionValue) {
    return new FunctionValue(value.call) as T extends FunctionValue ? T : never;
  }
  throw new Error("Unexpected prop value"); // should not be reachable
}

export function applyEdits(
  node: DocumentNode,
  element: ElementState,
  options: EditingOptions = { safe: false },
): DocumentNode {
  const textOverrides = new Map(element.texts ?? []);
  if (node instanceof TextNode) {
    return new TextNode(textOverrides.get(node.text) ?? node.text);
  }
  if (node instanceof ValueNode) {
    return new ValueNode(node.value);
  }
  if (node instanceof SlotNode) {
    if (options.safe) {
      let slot: ElementState[] | undefined;
      if (node.isList) {
        [, slot] = match(element.lists, node) ?? [];
      } else {
        [, slot] = match(element.slots, node) ?? [];
      }
      if (slot == null) {
        return new ContainerNode(node.children);
      }
      const candidates = node.children
        .map((child) => {
          if (child instanceof ElementNode) {
            return child;
          }
        })
        .filter((el): el is ElementNode => el != null);

      return new SlotNode(
        node.match,
        slot
          .map((item) => {
            const candidate = candidates.find((el) => el.match(item.name));
            if (candidate != null) {
              return applyEdits(candidate, item, options);
            }
          })
          .filter((el): el is DocumentNode => el != null),
        true,
      );
    } else {
      let slot: ElementState[];
      if (node.isList) {
        [, slot] = defined(match(element.lists, node), `No list slot matched`);
      } else {
        [, slot] = defined(match(element.slots, node), `No slot matched`);
      }
      const candidates = node.children.map((child) => {
        if (child instanceof ElementNode) {
          return child;
        }
        throw new Error("Unsupported child type, only ElementAnchor is allowed in ListAnchor");
      });
      return new SlotNode(
        node.match,
        slot.map((item) => {
          return applyEdits(
            defined(
              candidates.find((el) => el.match(item.name)),
              `Element ${item.name} is not defined`,
            ),
            item,
            options,
          );
        }),
        true,
      );
    }
  }
  const children = node.children.map((child) => applyEdits(child, element, options));
  if (node instanceof HTMLNode) {
    return new HTMLNode(node.tagName, applyPropEdits(node.props, element), children);
  }
  if (node instanceof ComponentNode) {
    return new ComponentNode(node.Component, applyPropEdits(node.props, element), children);
  }
  if (node instanceof ElementNode) {
    return new ElementNode(node.match, children);
  }
  if (node instanceof ContainerNode) {
    return new ContainerNode(children);
  }
  throw new Error("Unsupported node type");
}
