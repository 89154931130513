import * as React from "react";
import { useState, useEffect, useContext, useReducer, useCallback, useMemo, useRef } from "react";
import {
  AnimatePresence,
  motion,
  useScroll,
  useSpring,
  useTransform,
  useInView,
} from "framer-motion";
import clsx from "clsx";
import { importRegistry } from "../import-registry";
import "./relume-ui";
import "./react-icons-bi";
import "./react-icons-rx";
import "./react-icons-md";
import "./react-icons-fa6";

importRegistry.registerImports([
  [React, "React", "namespace", "react"],
  [clsx, "clsx", "default", "clsx"],
  [useState, "useState", "named", "react"],
  [useEffect, "useEffect", "named", "react"],
  [useContext, "useContext", "named", "react"],
  [useReducer, "useReducer", "named", "react"],
  [useCallback, "useCallback", "named", "react"],
  [useMemo, "useMemo", "named", "react"],
  [useRef, "useRef", "named", "react"],
]);

const framerMotion = "framer-motion";
importRegistry.registerImports([
  [AnimatePresence, "AnimatePresence", "named", framerMotion],
  [useScroll, "useScroll", "named", framerMotion],
  [useSpring, "useSpring", "named", framerMotion],
  [useTransform, "useTransform", "named", framerMotion],
  [useInView, "useInView", "named", framerMotion],
]);

const motionRegistered = importRegistry.registerImport(motion, "motion", "named", framerMotion);
importRegistry.registerDerived([
  [motion.div, motionRegistered.div],
  [motion.span, motionRegistered.span],
  [motion.img, motionRegistered.img],
  [motion.button, motionRegistered.button],
  [motion.nav, motionRegistered.nav],
  [motion.h1, motionRegistered.h1],
  [motion.h2, motionRegistered.h2],
]);
