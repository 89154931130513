import { importRegistry } from "../import-registry";
import CarouselApi from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import {
  // Accordion Components
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,

  // Breadcrumb Components
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,

  // Carousel Components
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,

  // Dialog Components
  Dialog,
  DialogContent,
  DialogTrigger,

  // Form Components
  Button,
  Checkbox,
  Input,
  Label,
  RadioGroup,
  RadioGroupItem,
  Switch,
  Textarea,

  // Select Components
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,

  // Tabs Components
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,

  // Sheet Components
  SheetClose,
  SheetContent,
  SheetTrigger,

  // Other Components
  VideoIframe,

  // Utilities
  buttonVariants,
  useMediaQuery,
} from "@relume_io/relume-ui";

const relumeUi = "@relume_io/relume-ui";

// Register imports grouped by component type
importRegistry.registerImports([
  // Accordion Registration
  [Accordion, "Accordion", "named", relumeUi],
  [AccordionContent, "AccordionContent", "named", relumeUi],
  [AccordionItem, "AccordionItem", "named", relumeUi],
  [AccordionTrigger, "AccordionTrigger", "named", relumeUi],

  // Breadcrumb Registration
  [Breadcrumb, "Breadcrumb", "named", relumeUi],
  [BreadcrumbItem, "BreadcrumbItem", "named", relumeUi],
  [BreadcrumbLink, "BreadcrumbLink", "named", relumeUi],
  [BreadcrumbList, "BreadcrumbList", "named", relumeUi],
  [BreadcrumbSeparator, "BreadcrumbSeparator", "named", relumeUi],

  // Carousel Registration
  [Carousel, "Carousel", "named", relumeUi],
  [CarouselContent, "CarouselContent", "named", relumeUi],
  [CarouselItem, "CarouselItem", "named", relumeUi],
  [CarouselNext, "CarouselNext", "named", relumeUi],
  [CarouselPrevious, "CarouselPrevious", "named", relumeUi],
  [CarouselApi, "CarouselApi", "named", "embla-carousel-react"],
  [Autoplay, "Autoplay", "default", "embla-carousel-autoplay"],

  // Dialog Registration
  [Dialog, "Dialog", "named", relumeUi],
  [DialogContent, "DialogContent", "named", relumeUi],
  [DialogTrigger, "DialogTrigger", "named", relumeUi],

  // Form Components Registration
  [Button, "Button", "named", relumeUi],
  [Checkbox, "Checkbox", "named", relumeUi],
  [Input, "Input", "named", relumeUi],
  [Label, "Label", "named", relumeUi],
  [RadioGroup, "RadioGroup", "named", relumeUi],
  [RadioGroupItem, "RadioGroupItem", "named", relumeUi],
  [Switch, "Switch", "named", relumeUi],
  [Textarea, "Textarea", "named", relumeUi],

  // Select Registration
  [Select, "Select", "named", relumeUi],
  [SelectContent, "SelectContent", "named", relumeUi],
  [SelectItem, "SelectItem", "named", relumeUi],
  [SelectTrigger, "SelectTrigger", "named", relumeUi],
  [SelectValue, "SelectValue", "named", relumeUi],

  // Tabs Registration
  [Tabs, "Tabs", "named", relumeUi],
  [TabsContent, "TabsContent", "named", relumeUi],
  [TabsList, "TabsList", "named", relumeUi],
  [TabsTrigger, "TabsTrigger", "named", relumeUi],

  // Sheet Registration
  [SheetClose, "SheetClose", "named", relumeUi],
  [SheetContent, "SheetContent", "named", relumeUi],
  [SheetTrigger, "SheetTrigger", "named", relumeUi],

  // Other Components Registration
  [VideoIframe, "VideoIframe", "named", relumeUi],

  // Utilities Registration
  [buttonVariants, "buttonVariants", "named", relumeUi],
  [useMediaQuery, "useMediaQuery", "named", relumeUi],
]);
