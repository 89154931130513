import * as React from "react";
import { createContextManager } from "../shared";
import { ElementAnchor, ListAnchor, SlotAnchor } from "./components";
import {
  ComponentNode,
  ContainerNode,
  DocumentNode,
  ElementNode,
  HTMLNode,
  SlotNode,
  TextNode,
  ValueNode,
} from "./document";
import {
  ArrayValue,
  DocumentNodeValue,
  FunctionValue,
  ManagedValue,
  NullishValue,
  ObjectValue,
  PrimitiveValue,
} from "./properties";
import { isTrackedObject } from "./values";

export const ELEMENT_NAME_KEY = "elementName";
export const SLOT_NAME_KEY = "slotName";
export const LIST_NAME_KEY = "listName";

export function parseNode(node: React.ReactNode): DocumentNode {
  if (isTrackedObject(node)) {
    return new ValueNode(node);
  }
  if (node == null) {
    return new ContainerNode([]);
  }
  if (typeof node === "string") {
    return new TextNode(node);
  }
  if (typeof node === "number") {
    return new TextNode(node.toString());
  }
  if (typeof node === "boolean") {
    return new ContainerNode([]);
  }
  if (Symbol.iterator in node) {
    return new ContainerNode(parseChildren(node));
  }
  if (React.isValidElement(node)) {
    if (node.props[ELEMENT_NAME_KEY] != null) {
      const elementName = node.props[ELEMENT_NAME_KEY];
      return new ElementNode((key: string) => key === elementName, [parseElement(node)]);
    }
    return parseElement(node);
  }

  console.error("Unsupported react node: ", node);
  throw new Error("Unsupported react node");
}

// set a context to track visited objects so we can detect circular references.
const withObjectPath = createContextManager<readonly unknown[]>([]);

export function parseValue(value: unknown): ManagedValue {
  return withObjectPath((path, setPath) => {
    if (value == null) {
      return new NullishValue();
    }
    if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
      return new PrimitiveValue(value);
    }
    if (typeof value === "function") {
      return new FunctionValue(value);
    }

    if (path.includes(value)) {
      console.error("Circular reference detected, visited objects: ", ...path, value);
      throw new Error("Circular reference detected, unable to serialize props and apply edits");
    }
    setPath([...path, value]);

    if (Array.isArray(value)) {
      return new ArrayValue(value, value.map(parseValue));
    }
    if (React.isValidElement(value)) {
      return new DocumentNodeValue(value, parseNode(value));
    }
    if (typeof value === "object") {
      return parseObject(value);
    }
    console.error("Unsupported prop value: ", value);
    throw new Error("Unsupported prop value");
  });
}

function parseObject(value: object, extra?: { ref?: unknown }): ObjectValue {
  const entries: [string, ManagedValue][] = Object.entries(value)
    .filter(([key]) => ![ELEMENT_NAME_KEY, SLOT_NAME_KEY, LIST_NAME_KEY, "children"].includes(key))
    .map(([key, value]) => [key, parseValue(value)]);
  if (extra?.ref != null) {
    value = { ...value, ...extra };
    entries.push(["ref", parseValue(extra.ref)]);
  }
  return new ObjectValue(value, entries);
}

function parseChildren(children: React.ReactNode | React.ReactNode[]): DocumentNode[] {
  const results: DocumentNode[] = [];
  function walk(children: React.ReactNode | React.ReactNode[]) {
    if (Array.isArray(children)) {
      for (const child of children) {
        walk(child);
      }
    } else if (children != null && typeof children !== "boolean") {
      results.push(parseNode(children));
    }
  }
  walk(children);
  return results;
}

function parseElement(element: React.ReactElement): DocumentNode {
  const elementType = element.type;

  let children = parseChildren(element.props.children);
  if (element.props[SLOT_NAME_KEY] != null) {
    const slotName = element.props[SLOT_NAME_KEY];
    children = [new SlotNode((key: string) => key === slotName, children, false)];
  } else if (element.props[LIST_NAME_KEY] != null) {
    const listName = element.props[LIST_NAME_KEY];
    children = [new SlotNode((key: string) => key === listName, children, true)];
  }

  if (elementType === ListAnchor) {
    return new SlotNode(toMatchFunction(element.props), children, true);
  }
  if (elementType === SlotAnchor) {
    return new SlotNode(toMatchFunction(element.props), children, false);
  }
  if (elementType === ElementAnchor) {
    return new ElementNode(toMatchFunction(element.props), children);
  }

  if (elementType === React.Fragment) {
    return new ContainerNode(children);
  }
  // extract ref to undo the special props treatment by React
  const ref = "ref" in element ? element.ref : undefined;
  if (typeof elementType === "string") {
    return new HTMLNode(elementType, parseObject(element.props, { ref }), children);
  }
  return new ComponentNode(elementType, parseObject(element.props, { ref }), children);
}

export function toMatchFunction(props: { name: string } | { match: (name: string) => boolean }) {
  if ("name" in props) {
    return (key: string) => key === props.name;
  }
  return props.match;
}
