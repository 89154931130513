import { DocumentNode } from "./document";
import { ArrayValue, DocumentNodeValue, ManagedValue, ObjectValue } from "./properties";

function* searchFromValue<N extends DocumentNode>(
  value: ManagedValue,
  condition: (node: DocumentNode) => node is N,
): Generator<N> {
  if (value instanceof DocumentNodeValue) {
    yield* searchFromTree<N>(value.node, condition);
  } else if (value instanceof ObjectValue) {
    for (const [, item] of value.entries) {
      yield* searchFromValue<N>(item, condition);
    }
  } else if (value instanceof ArrayValue) {
    for (const item of value.items) {
      yield* searchFromValue<N>(item, condition);
    }
  }
}

/**
 * search for nodes that satisfy @param condition from the given @param node
 * children of the matched node won't be searched
 *
 * @param includeSelf whether to include the node itself in the search
 */
export function* searchFromTree<N extends DocumentNode>(
  node: DocumentNode,
  condition: (node: DocumentNode) => node is N,
  includeSelf = false,
): Generator<N> {
  if (includeSelf && condition(node)) {
    yield node;
  } else {
    if ("children" in node) {
      for (const child of node.children) {
        if (condition(child)) {
          yield child;
        } else {
          yield* searchFromTree<N>(child, condition);
        }
      }
    }
    if ("props" in node) {
      yield* searchFromValue<N>(node.props, condition);
    }
  }
}
