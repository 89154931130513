import * as React from "react";
import { isTrackedObject, getTracker, ReferenceTracker } from "../ast";
import {
  HTMLNode,
  TextNode,
  ComponentNode,
  ContainerNode,
  DocumentNode,
  ManagedValue,
  NullishValue,
  PrimitiveValue,
  FunctionValue,
  ArrayValue,
  ObjectValue,
  DocumentNodeValue,
} from "../ast";
import { createContextManager, defined } from "../shared";
import { ValueNode } from "../ast/document";

const withRenderingContext = createContextManager<
  | {
      locales: Map<unknown, unknown>;
    }
  | undefined
>(undefined);

function dereference(
  tracker: ReferenceTracker,
  locales: Map<unknown, unknown>,
  last: unknown = null,
): unknown {
  if (locales.has(tracker)) {
    return locales.get(tracker)!;
  }
  let current = tracker.target.value;

  if (isTrackedObject(current)) {
    const inner = getTracker(current);
    current = dereference(inner, locales, current);
  }

  for (const access of tracker.accesses) {
    if (access.type === "get") {
      current = (current as never)[access.field];
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-types
      current = (current as Function).apply(last, access.args.map(unwrap));
    }
  }
  locales.set(tracker, current);
  return current;
}

function followTrackedObject(value: object): unknown {
  return withRenderingContext((context) => {
    const locales = defined(context).locales;
    return dereference(getTracker(value), locales);
  });
}

function unwrap(value: NullishValue): undefined;
function unwrap(value: PrimitiveValue): PrimitiveValue["value"];
function unwrap(value: FunctionValue): FunctionValue["call"];
function unwrap(value: ArrayValue): unknown[];
function unwrap(value: ObjectValue): Record<string, unknown>;
function unwrap(value: DocumentNodeValue): React.ReactNode;
function unwrap(value: ManagedValue): unknown;
function unwrap<T extends ManagedValue>(value: T): unknown {
  if (value instanceof FunctionValue && isTrackedObject(value.call)) {
    return followTrackedObject(value.call);
  } else if (value instanceof NullishValue) {
    return undefined;
  } else if (value instanceof PrimitiveValue) {
    return value.value;
  } else if (value instanceof FunctionValue) {
    return value.call;
  } else if (value instanceof ArrayValue) {
    return value.items.map(unwrap);
  } else if (value instanceof ObjectValue) {
    return Object.fromEntries(value.entries.map(([k, v]) => [k, unwrap(v)]));
  } else if (value instanceof DocumentNodeValue) {
    return renderToReactNode(value.node);
  } else {
    throw new Error("Unexpected managed value type");
  }
}

function render(node: DocumentNode): React.ReactNode {
  if (node instanceof ValueNode) {
    if (isTrackedObject(node.value)) {
      return followTrackedObject(node.value) as string | number;
    }
    return node.value as string | number;
  }
  if (node instanceof TextNode) {
    return node.text;
  }

  let children: React.ReactNode[] | undefined = React.Children.toArray(node.children.map(render));
  children = children.length > 0 ? children : undefined;
  if (node instanceof HTMLNode) {
    return React.createElement(node.tagName, unwrap(node.props), children);
  }
  if (node instanceof ComponentNode) {
    const Component = isTrackedObject(node.Component)
      ? (followTrackedObject(node.Component) as React.ComponentType)
      : node.Component;
    return React.createElement(Component, unwrap(node.props), children);
  }
  if (node instanceof ContainerNode) {
    return children;
  }
}

export function renderToReactNode(node: DocumentNode): React.ReactNode {
  return withRenderingContext((_, setContext) => {
    setContext({ locales: new Map() });
    return render(node);
  });
}
