import { NodeProperties } from "./properties";

class BaseDocumentNode {}

export class TextNode extends BaseDocumentNode {
  constructor(public readonly text: string) {
    super();
  }
}

export class ValueNode extends BaseDocumentNode {
  constructor(public readonly value: unknown) {
    super();
  }
}

export class ContainerNode extends BaseDocumentNode {
  constructor(public readonly children: DocumentNode[]) {
    super();
  }
}

export class HTMLNode extends ContainerNode {
  constructor(
    public readonly tagName: string,
    public readonly props: NodeProperties,
    public readonly children: DocumentNode[],
  ) {
    super(children);
  }
}

export class ComponentNode extends ContainerNode {
  constructor(
    public readonly Component: React.ComponentType,
    public readonly props: NodeProperties,
    public readonly children: DocumentNode[],
  ) {
    super(children);
  }
}

export class AnnotationNode extends ContainerNode {}

export class SlotNode extends AnnotationNode {
  constructor(
    public readonly match: (key: string) => boolean,
    public readonly children: DocumentNode[],
    public readonly isList: boolean,
  ) {
    super(children);
  }
}

export class ElementNode extends AnnotationNode {
  constructor(
    public readonly match: (key: string) => boolean,
    public readonly children: DocumentNode[],
  ) {
    super(children);
  }
}

export type DocumentNode =
  | TextNode
  | ValueNode
  | ContainerNode
  | HTMLNode
  | ComponentNode
  | AnnotationNode
  | SlotNode
  | ElementNode;
